import type { Node, Edge } from '@vue-flow/core'  
import { Position, useVueFlow } from '@vue-flow/core'

export const LEVEL_1_NESTED_NODE_WIDTH = `${300 - 28}px`;
export const LEVEL_2_NESTED_NODE_WIDTH = `${300 - 28 - 20}px`;
export const LEVEL_2_NESTED_NODE_HEIGHT = 32;

export const useWorkflowStore = defineStore("workflow", () => {
  const workflow = ref({
    id: "",
    name: "",
    status: ""
  })

  const { findNode, updateNode, updateNodeData, getConnectedEdges, project } = useVueFlow()

  const nodes = ref<Node[]>([])

  const textWrapperHeight = ref<{
    [key: string]: number;
  }>({})

  function addNewNode({
    type,
    data,
  }: {
    type: string;
    data: any;
  }) {
    const { x, y } = project({ x: window.innerWidth / 2, y: window.innerHeight / 2 });
    const id = crypto.randomUUID();
    const randomOffset = (Math.floor(Math.random() * 100));
    nodes.value.push({
      id,
      type,
      selected: true,
      position: {
        x: x - randomOffset * 3,
        y: y - randomOffset,
      },
      data,
    })
    const selectedNodes = nodes.value.filter((node) => {
      return node?.selected && node.id !== id
    })
    // console.log(selectedNodes)
    selectedNodes.forEach((node) => {
      node.selected = false
    })
    toggleSettingPanel({
      isOpen: true,
      type,
      nodeId: id,
    })
  }

  function addInteractiveOptionNode({
    parentId,
    type,
    data,
  }: {
    parentId?: string
    type: string;
    data: any;
  }) {
    const parentNode = findNode(parentId)
    if (!parentNode) {
      console.error("parentNode not found", parentId)
      return
    }
    if (!Array.isArray(parentNode?.data?.subNodeIds)) {
      parentNode.data.subNodeIds = []
    }
    let yPosition = 0;
    const id = crypto.randomUUID();
    const isLevel2Node = type.startsWith("button");
    const nodesInParent = parentNode.data.subNodeIds;
    const previousNodeId = nodesInParent[nodesInParent.length - 1]
    const previousNode = findNode(previousNodeId)

    // console.log('previousNode', {
    //   isLevel2Node,
    //   nodesInParent,
    //   previousNode,
    // })

    if (nodesInParent.length > 0 && isLevel2Node && previousNode) {
      yPosition = previousNode.position.y + LEVEL_2_NESTED_NODE_HEIGHT + 10;
    } else if (!isLevel2Node) {
      yPosition = (previousNode?.position?.y || 0) + (previousNode?.dimensions?.height || 0) + 10;
    } else {
      yPosition = textWrapperHeight.value[parentId] + 20;
    }

    parentNode.data.subNodeIds.push(id)

    nodes.value.push({
      id,
      type,
      data,
      ...(parentId ? {
        draggable: false,
        parentNode: parentId,
        position: {
          x: isLevel2Node ? 10 : 14,
          y: yPosition
        },
        style: {
          width: isLevel2Node ? LEVEL_2_NESTED_NODE_WIDTH : LEVEL_1_NESTED_NODE_WIDTH
        }
      } : {
        targetPosition: Position.Left,
        position: {
          x: 10,
          y: 36
        },
      }),
      sourcePosition: Position.Right,
    })
  }

  function updateNodeHeight(id: string, height: number) {
    updateNode(id, {
      style: {
        height: `${height}px`,
      },
    })
  }

  function updateNodePositionY(id: string, y: number) {
    const node = findNode(id)
    if (!node) {
      console.error("node not found", id)
      return
    }
    updateNode(id, {
      position: {
        x: node.position.x,
        y,
      },
    })
  }

  function removeNode(id: string) {
    const nodesToRemove = new Set<string>();
    const edgesToRemove = new Set<string>();
    
    // Recursively collect all nested nodes to remove
    function collectNodesToRemove(nodeId: string) {
      nodesToRemove.add(nodeId);
      
      // Find connected edges for this node and mark them for removal
      const connectedEdges = getConnectedEdges(nodeId);
      for (const edge of connectedEdges) {
        edgesToRemove.add(edge.id);
      }
      
      // Find any child nodes that need to be removed
      const childNodes = nodes.value.filter(node => node.parentNode === nodeId);
      for (const childNode of childNodes) {
        collectNodesToRemove(childNode.id);
      }
    }
    
    collectNodesToRemove(id);
    
    // Update parent node's subNodeIds if applicable
    const node = findNode(id);
    if (node?.parentNode) {
      const parentNode = findNode(node.parentNode);
      if (parentNode && Array.isArray(parentNode.data.subNodeIds)) {
        parentNode.data.subNodeIds = parentNode.data.subNodeIds.filter(
          (subNodeId: string) => subNodeId !== id
        );
      }
    }
    
    // console.log('edgesToRemove', edgesToRemove)
    edges.value = edges.value.filter(edge => !edgesToRemove.has(edge.id));
    
    // console.log('nodesToRemove', nodesToRemove)
    nodes.value = nodes.value.filter(node => !nodesToRemove.has(node.id));
  }

  /**
   * Edges
   */
  const edges = ref<Edge[]>([])
  const isConnectingEdge = ref(false)

  function onEdgeUpdate({ edge, connection }) {
    // console.log('update', edge, connection)
    const updatedEdge = edges.value.find(({ id }) => id === edge.id)
    if (!updatedEdge) return;
    // console.log(updatedEdge)
    updatedEdge.source = connection.source;
    updatedEdge.target = connection.target;
  }

  function onEdgeConnected(params) {
    // console.log("connected", params)
    edges.value.push({
      id: crypto.randomUUID(),
      source: params.source,
      target: params.target,
      sourceHandle: null,
      targetHandle: null,
    })
  }

  // Track the current trigger being edited
  const editingTrigger = ref<
    {
      id: string;
      type: string;
      intent: string;
    } | null
  >(null);

  const triggerNode = computed(() => {
    return nodes.value.find((node) => node.type === 'trigger');
  })
  
  const triggers = computed(() => {
    return triggerNode.value?.data.triggers || [];
  })

  function addNewTrigger() {
    // Create an empty trigger for editing
    editingTrigger.value = {
      id: crypto.randomUUID(), // Generate a unique ID
      type: "intent",
      intent: "",
    };

    toggleSettingPanel({
      isOpen: true,
      type: "trigger-edit",
      title: "New Trigger",
      goBack: {
        type: "trigger",
        title: "Triggers",
      },
    });
  }

  function saveTrigger(trigger: { id: string; type: string; intent: string }) {
    const node = findNode(triggerNode.value.id)
    if (!node) {
      console.error("triggerNode not found", triggerNode.value.id);
      return;
    }
    const index = node.data.triggers.findIndex((t) => t.id === trigger.id);

    if (index !== -1) {
      node.data.triggers[index] = { ...trigger };
    } else {
      node.data.triggers.push({ ...trigger });
    }

    editingTrigger.value = null;
  }

  function editTrigger(id: string) {
    const trigger = triggers.value.find((trigger) => trigger.id === id);
    if (!trigger) {
      console.error("trigger not found", id);
      return;
    }
    editingTrigger.value = { ...trigger };

    toggleSettingPanel({  
      isOpen: true,
      type: "trigger-edit",
      title: "Contact sends a message",
      goBack: {
        type: "trigger",
        title: "Triggers",
      },
    });
  }

  function updateTrigger(id: string, data: { intent: string }) {
    const node = findNode(triggerNode.value.id)
    if (!node) {
      console.error("triggerNode not found", triggerNode.value.id);
      return;
    }
    const triggerIndex = node.data.triggers.findIndex((trigger) => trigger.id === id);
    if (triggerIndex !== -1) {
      node.data.triggers[triggerIndex] = {
        ...node.data.triggers[triggerIndex],
        ...data,
      };
    }
  }

  function deleteTrigger(id: string) {
    const node = findNode(triggerNode.value.id)
    if (!node) {
      console.error("triggerNode not found", triggerNode.value.id);
      return;
    }
    node.data.triggers = node.data.triggers.filter((trigger) => trigger.id !== id);
  }

  const settingPanel = ref({
    isOpen: false,
    nodeId: "",
    type: "",
    title: "",
    goBack: {
      type: "",
      title: "",
    },
  });

  function toggleSettingPanel({
    isOpen = true,
    nodeId = "",
    type,
    title = "",
    goBack = {
      type: "",
      title: "",
    },
  }: {
    isOpen: boolean;
    nodeId?: string;
    type: string;
    title?: string;
    goBack?: { type: string; title: string };
  }) {
    settingPanel.value = {
      isOpen,
      nodeId,
      type,
      title,
      goBack,
    };
  }

  function backToPreviousScreen() {
    toggleSettingPanel({
      isOpen: true,
      type: settingPanel.value.goBack.type,
      title: settingPanel.value.goBack.title,
      goBack: {
        type: "",
        title: "",
      },
    });
  }

  return {
    workflow,

    //* Nodes
    nodes,
    textWrapperHeight,
    addNewNode,
    addInteractiveOptionNode,
    updateNodeData,
    updateNodeHeight,
    updateNodePositionY,
    findNode,
    removeNode,

    //* Edges
    edges,
    isConnectingEdge,
    onEdgeUpdate,
    onEdgeConnected,

    //* Setting Panel
    settingPanel,
    toggleSettingPanel,
    backToPreviousScreen,

    //* Triggers
    triggers,
    editingTrigger,
    editTrigger,
    updateTrigger,
    deleteTrigger,
    addNewTrigger,
    saveTrigger,
  };
});

export const mediaConfigurations = {
  send_image: {
    type: 'image',
    hasBackgroundImage: true,
    allowedTypes: ['image/jpeg', 'image/jpg', 'image/png'],
    extensions: '.jpg,.jpeg,.png',
    maxSizeInBytes: 5 * 1024 * 1024, // 5MB
    maxSizeLabel: '5MB',
    icon: 'i-lucide-image'
  },
  send_video: {
    type: 'video',
    hasBackgroundImage: false,
    allowedTypes: ['video/mp4'],
    extensions: '.mp4',
    maxSizeInBytes: 16 * 1024 * 1024, // 16MB
    maxSizeLabel: '16MB',
    icon: 'i-lucide-film'
  },
  send_document: {
    type: 'document',
    hasBackgroundImage: false,
    allowedTypes: [
      'application/pdf', 
      'text/plain', 
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ],
    extensions: '.pdf,.txt,.xls,.xlsx,.doc,.docx',
    maxSizeInBytes: 20 * 1024 * 1024, // 20MB
    maxSizeLabel: '20MB',
    icon: 'i-lucide-file-text'
  }
};

export interface NodePosition {
  x: number;
  y: number;
}