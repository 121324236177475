export const useNodeTransformation = () => {
  const nodeCreators = {
    trigger: createTriggerNode,
    send_message: createMessageNode,
    action: createActionNode
  };

  function createTriggerNode(node) {
    const baseAttributes = getBaseAttributes(node);
    return [{
      ...baseAttributes,
      data: {
        triggers: node.workflow_trigger_conditions,
      }
    }];
  }

  function createMessageNode(node) {
    const baseAttributes = getBaseAttributes(node);
    const nodes = [{
      ...baseAttributes,
      data: {
        subNodeIds: (node?.workflow_steps ?? []).map((step) => step.id),
      },
    }];
    
    // Add child steps
    node.workflow_steps.forEach((step) => {
      nodes.push(createMessageStep(step, node.id));
      
      // Add interactive options for this step
      if (step?.workflow_interactive_options?.length > 0) {
        step.workflow_interactive_options
          .sort((a, b) => a.order - b.order)
          .forEach((option) => {
            nodes.push(createInteractiveOption(option, step.id));
          });
      }
    });
    
    return nodes;
  }

  function createMessageStep(step, parentNodeId) {
    return {
      id: step.id,
      type: step.type,
      position: step.position,
      draggable: false,
      data: {
        ...step.data,
        subNodeIds: (step?.workflow_interactive_options ?? [])
          .sort((a, b) => a.order - b.order)
          .map((option) => option.id) ?? [],
      },
      parentNode: parentNodeId,
    };
  }

  function createInteractiveOption(option, parentStepId) {
    return {
      id: option.id,
      type: option.type,
      position: option.position,
      draggable: false,
      data: {
        title: option.title,
        description: option?.description,
      },
      parentNode: parentStepId,
    };
  }

  function createActionNode(node) {
    const baseAttributes = getBaseAttributes(node);
    return [{
      ...baseAttributes,
      data: {
        actions: (node?.workflow_steps ?? []).map((step) => ({
          ...step.data,
          id: step.id,
          type: step.type,
        })),
      }
    }];
  }

  function getBaseAttributes(node) {
    return {
      id: node.id,
      type: node.type,
      position: node.position,
    };
  }

  function createWorkflowNodes(nodes) {
    return nodes.flatMap(node => {
      const creator = nodeCreators[node.type];
      return creator ? creator(node) : [];
    });
  }

  function transformToDbFormat({
    workflow,
    nodes,
    edges,
    findNode,
  }: {
    workflow: any;
    nodes: any;
    edges: any;
    findNode: any;
  }) {
    const workflowId = workflow.id;
    
    // Initialize collections for each table
    const workflowNodes = [];
    const workflowTriggerConditions = [];
    const workflowSteps = [];
    const workflowInteractiveOptions = [];
    const workflowEdges = [];
    
    // Process nodes
    const nodesByType = {
      trigger: [],
      send_message: [],
      action: [],
    };
    
    // First pass: categorize nodes by type and identify parent-child relationships
    nodes.forEach(node => {
      if (!node.parentNode) {
        // This is a top-level node
        nodesByType[node.type]?.push({
          ...node,
          position: findNode(node.id)?.position
        });
      }
    });
    
    // Process trigger nodes
    nodesByType.trigger.forEach(node => {
      workflowNodes.push({
        id: node.id,
        workflow_id: workflowId,
        type: node.type,
        position: node.position,
      });
      
      // Process trigger conditions
      if (node.data?.triggers) {
        node.data.triggers.forEach(trigger => {
          workflowTriggerConditions.push({
            id: trigger.id,
            workflow_node_id: node.id,
            type: trigger.type,
            intent: trigger.intent,
          });
        });
      }
    });
    
    // Process message nodes
    nodesByType.send_message.forEach(node => {
      workflowNodes.push({
        id: node.id,
        workflow_id: workflowId,
        type: node.type,
        position: node.position,
      });
      
      // Find child steps (direct children of this node)
      const childSteps = nodes.filter(n => n.parentNode === node.id);
      
      childSteps.forEach(step => {
        const stepData = {
          id: step.id,
          workflow_node_id: node.id,
          type: step.type,
          data: step.data || {},
          order: (node?.data?.subNodeIds ?? []).indexOf(step.id), // Set order based on array position
          position: step.position,
        };
        
        workflowSteps.push(stepData);
        
        // Find interactive options (children of this step)
        const interactiveOptions = nodes.filter(n => n.parentNode === step.id);
        
        interactiveOptions.forEach(option => {
          workflowInteractiveOptions.push({
            id: option.id,
            workflow_step_id: step.id,
            type: option.type,
            title: option.data?.title || '',
            description: option.data?.description || '',
            order: (stepData?.data?.subNodeIds ?? []).indexOf(option.id), // Set order based on array position
            position: option.position,
          });
        });

        if (stepData.data.subNodeIds) {
          delete stepData.data.subNodeIds;
        }
      });
    });
    
    // Process action nodes
    nodesByType.action.forEach(node => {
      workflowNodes.push({
        id: node.id,
        workflow_id: workflowId,
        type: node.type,
        position: node.position,
      });
      
      // Process action steps
      if (node.data?.actions) {
        node.data.actions.forEach((action, index) => {
          workflowSteps.push({
            id: action.id,
            workflow_node_id: node.id,
            type: action.type,
            data: {
              ...(action.type === 'assign_conversation' && {
                user_id: action?.user_id,
              })
            },
            order: index,
            position: { x: 0, y: index * 50 }, // Default positioning
          });
        });
      }
    });
    
    // Process edges
    edges.forEach(edge => {
      const sourceNode = nodes.find(n => n.id === edge.source);

      if (!sourceNode) return;
      
      let dbEdge = {
        id: edge.id,
        workflow_id: workflowId,
        source_node_id: edge?.source,
        target_node_id: edge?.target,
        trigger_type: 'node_completed',
        trigger_step_id: null,
        trigger_element_id: null,
      };
      
      // Handle trigger elements (buttons, etc.)
      if (sourceNode.parentNode) {
        const parentNode = nodes.find(n => n.id === sourceNode.parentNode);
        if (parentNode?.parentNode) {
          // This is a level 2 node (interactive option)
          dbEdge = {
            ...dbEdge,
            source_node_id: parentNode.parentNode,
            trigger_type: 'option_click',
            trigger_step_id: parentNode.id,
            trigger_element_id: edge?.source,
          }
        }
      }
      
      workflowEdges.push(dbEdge);
    });
    
    return {
      workflow,
      workflow_nodes: workflowNodes,
      workflow_trigger_conditions: workflowTriggerConditions,
      workflow_steps: workflowSteps,
      workflow_interactive_options: workflowInteractiveOptions,
      workflow_edges: workflowEdges,
    };
  }

  return {
    createWorkflowNodes,
    transformToDbFormat
  }
}